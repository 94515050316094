<template>
  <div>
    <validation-observer ref="simple">
      <b-form>
        <b-card-code title="  إضافة  متطوع">
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-row>

              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="name"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  اسم المتطوع</label>
                    <b-form-input
                      id="input-default"
                      v-model="name"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="3"
                xl="3"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="file"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>  الرقم </label>
                    <b-form-input
                      id="input-default"
                      v-model="number"
                      type="number"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row>
            <!-- <b-row>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="area"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>المنطقة </label>
                    <b-form-textarea
                      id="input-default"
                      v-model="area"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    label-for="notes"
                    :state="errors.length > 0 ? false : null"
                  >

                    <label>العنوان </label>
                    <b-form-textarea
                      id="input-default"
                      v-model="address"
                      type="text"
                    />
                  </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
            </b-row> -->
            <hr />

          <h3>العنوان:   </h3>
          <br />
            <b-row>
              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                <b-form-group label-for="townbycity">
                <label> المنطقة</label>
                <v-select
                  v-model="city"
                  :reduce="(val) => val.value"
                  :options="optionCity"
                  @input="getoptionTArea(city)"
                />
              </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                <b-form-group label-for="townbyvillage">
                <label> الناحية</label>
                <v-select
                  v-model="town"
                  :reduce="(val) => val.value"
                  :options="optionTVillages"
                  @input="getoptionTown(town)"
                />
              </b-form-group>
           
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>

              <b-col
                md="6"
                xl="6"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                <b-form-group label-for="newtown">
                <label> الحي</label>
                <v-select
                  id="input-default"
                  v-model="neighborhood_id"
                  :reduce="(val) => val.value"
                  :options="optionTown"
                  @input="getoptionStreet(neighborhood_id)"
                />
              </b-form-group>
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </validation-provider>
              </b-col>
              <b-col
              md="3"
              xl="2"
            >
              <b-form-group label-for="newtown">
                <label> تفاصيل الحي</label>
                <v-select
                  id="input-default"
                  v-model="street_id"
                  :reduce="(val) => val.value"
                  :options="optionStreet"
                />
              </b-form-group>
            </b-col>
            </b-row>

            <b-row>
              <b-col
                md="12"
                xl="12"
              >
                <b-button
                  variant="purple"
                  @click="addvolunteer"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle"> إضافة المتطوع</span>
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-card-code title=" المتطوعين">
      <!-- search input -->

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Name -->
          <span
            v-if="props.column.field === 'name'"
            class="text-nowrap"
          >
            <!-- <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          /> -->
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>

          <!-- Column: Status -->
          <!-- <span v-else-if="props.column.field === 'status'">
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status }}
          </b-badge>
        </span> -->

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'id'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-address align-middle mr-25"
                  />
                </template>
                <b-dropdown-item-button
                  @click="
                    $router.push({
                      name: 'edit-volunteer',
                      params: { id: props.row.id },
                    })
                  "
                >
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span>عرض</span>
                </b-dropdown-item-button>
                <!-- <b-dropdown-item-button >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>تعديل</span>
              </b-dropdown-item-button > -->
                <b-dropdown-item-button @click="deletevolunteer(props.row.id)">
                  <feather-icon
                    icon="TrashIcon"
                    class="mr-50"
                  />
                  <span>حذف</span>
                </b-dropdown-item-button>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-code>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import vSelect from 'vue-select'
import {
  BRow,
  BCol,
  BFormGroup,

  BDropdown,

  BDropdownItemButton,


  BFormInput,
  BFormTextarea,

  BButton,
  BForm,
  BFormInvalidFeedback,
} from 'bootstrap-vue'


export default {

  components: {
 
    BDropdown,
    required,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,
    vSelect,
    BCardCode,
    BButton,
    BFormInput,

    BFormGroup,

    BFormTextarea,

    BRow,

    BCol,
  },

  data() {
    return {
      number: '',
      address: '',
      name: '',

      area: '',
      rewardType: '',
      datatest: [],
      columns: [
        {
          label: 'الرقم ',
          field: 'number',
        },

        {
          label: ' المتطوع ',
          field: 'name',
        },

        {
          label: 'الخيارات',
          field: 'id',
          sortable: true,
        },
      ],
      rows: [],
      date: '',
      notes: '',
      employe: '',
      id: '',
      searchTerm: '',
      optionerewardType: [
      ],
      item: '',
      optionitem: [],
      optionemploye: [],
      optionTown: [],
      optionTVillages: [],
      optionCity:[],
      neighborhood_id:'',
      street_id:'',
      city:'',
      town:'',
      optionStreet:[]
  
    }
  },

  created() {
    this.getvolunteer()
    this. getoptionTCity(8)
  },

  methods: {

    deletevolunteer(id) {
      this.$swal({
        icon: 'warning',
        title: 'هل انت متأكد من الحذف',
        confirmButtonText: 'موافق',
        cancelButtonText: 'الغاء',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'لا',
        },
      }).then(result => {
        if (result.value) {
          const url = `/api/v1/volunteer/${id}`
          this.$http.delete(url).then(res => {
            // console.log(res);
            this.getvolunteer()
            this.$swal({
              icon: 'warning',
              title: 'تم الحذف  بنجاح',
              confirmButtonText: 'موافق',
              cancelButtonText: 'الغاء',
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'لا',
              },
            })
          })
        }
      })
    },

    getoptionTCity(id) {
      this.optionCity = []
      const url = `/api/v1/governorates/${id}/cities`
      this.$http.get(url).then(res => {
        const country = res.data.data
        country.forEach(el => {
          this.optionCity.push({ value: el.id, label: el.name })
        })
      })
    },

    getoptionTArea(id) {
      this.optionTVillages = []
      const url = `/api/v1/cities/${id}/towns`

      this.$http.get(url).then(res => {
        const country = res.data.data
        country.forEach(el => {
          // console.log(el)
          this.optionTVillages.push({ value: el.id, label: el.name })
        })
      })
    },

    getoptionTown(id) {
      this.optionTown = []

      const url = `/api/v1/towns/${id}/neighborhoods`
      this.$http.get(url).then(response => {
        const towns = response.data.data

        towns.forEach(el => {
          this.optionTown.push({ label: el.name, value: el.id })
        })
      })
    },
    getoptionStreet(id) {
      this.optionStreet = []

      const url = `/api/v1/neighborhoods/${id}/streets`
      this.$http.get(url).then(response => {
        const towns = response.data.data

        towns.forEach(el => {
          this.optionStreet.push({ label: el.name, value: el.id })
        })
      })
    },

    addvolunteer() {
      return new Promise((resolve, reject) => {
        this.$refs.simple
          .validate()
          .then(success => {
            if (success) {
              const data = {
                name: this.name,
                number: this.number,
                city_id: this.city,
                town_id: this.town,
                neighborhood_id: this.neighborhood_id,
                street_id:this.street_id
                
              }

              this.$http.post('/api/v1/volunteer', data).then(res => {
                this.getvolunteer()
                this.$swal({
                  title: '',
                  text: 'تمت أضافة المتطوع بنجاح',
                  icon: 'success',
                  confirmButtonText: 'موافق',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
            }
          })
      })
    },

    getvolunteer() {
      this.$http.get('/api/v1/volunteer').then(res => {
        // console.log('pact', res.data.data)
        this.rows = res.data
      })
    },

    //
  },
}
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}
.button-excel {
  border: none;
  padding: 0;
  background: none;
  color: white;
}
span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>
